

































































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { PaginatableMixin } from '@/views/mixin';
import { Letter, LetterList, State } from '@/modules/correspondence/types';
import { ApiResponse, Pagination } from '@/components/types';
import { FETCH_LETTER_LIST, SET_LETTER_PAGE } from '../store';
import DownloadAction from '../components/DownloadAction.vue';
import LetterBuildDialog from '../components/LetterBuildDialog.vue';
import UpdateLetterDialog from '../components/UpdateLetterDialog.vue';
import LetterPreviewDialog from '../components/LetterPreviewDialog.vue';
import MailLetterDialog from '../components/MailLetterDialog.vue';
import DeleteLetterDialog from '../components/DeleteLetterDialog.vue';
import AppTable from '@/components/AppTable.vue';
import CardHeader from '@/components/CardHeader.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    CardHeader,
    AppTable,
    DeleteLetterDialog,
    MailLetterDialog,
    LetterPreviewDialog,
    UpdateLetterDialog,
    LetterBuildDialog,
    DownloadAction
  }
})
export default class List extends PaginatableMixin {
  @Correspondence.State('letters') list!: LetterList;
  @Correspondence.Action(FETCH_LETTER_LIST) fetch!: () => Promise<ApiResponse>;
  @Correspondence.Mutation(SET_LETTER_PAGE) setPage!: (page: number) => void;

  loading: boolean = false;
  error: any = null;

  updateLetter: Letter | null = null;
  deleteLetter: Letter | null = null;
  mailLetter: Letter | null = null;
  preview: Letter | null = null;

  pagination: Pagination = {
    sortBy: ['created'],
    itemsPerPage: 10,
    page: 1,
    descending: true
  };

  get items(): Letter[] {
    return this.list.items.map((letter) => ({
      ...letter,
      createdTrans: format(letter.created, 'dd.MM.yyyy')
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'left', text: this.$i18n.t('common.label'), value: 'description' },
      { align: 'left', text: this.$i18n.t('common.conservatee'), value: 'conservatorship.conservatee' },
      { align: 'left', text: this.$i18n.t('common.receiver'), value: 'receiver.name' },
      ...(this.isAdmin() ? [
        { align: 'left', text: this.$i18n.t('common.creator'), value: 'creator.name' }
      ] : []),
      { align: 'left', text: this.$i18n.t('common.actions'), value: 'createdTrans', sortable: false }
    ];
  }

  itemClass(state: State): string {
    if (state === 'mailed') {
      return 'success';
    }

    return '';
  }

  btnClass(state: State): string {
    if (state === 'mailed') {
      return 'btn-success';
    }

    return 'btn-info';
  }

  async created() {
    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
